import React, { useContext } from "react";
import imageLeft from "../../Assets/Images/Home/coverLeft.png";
import imageRight from "../../Assets/Images/Home/coverRight.png";
import styles from "./home.module.css";
import Context from "../../Context";
import sharedAlbumLogo from "../../Assets/sharedAlbumLogo.png";
import coverAiLogo from "../../Assets/coverAiLogo.png";
import coverInvitationLogo from "../../Assets/coverInvitationLogo.jpeg";
import coverAutographLogo from "../../Assets/coverAutographLogo.png";
import coverAlbumLogo from "../../Assets/coverAlbumLogo.png";
import cameraIcon from "../../Assets/cameraIcon.png";
import photographerLogo from "../../Assets/photoGrapherLogo.png";

function Cover() {
  const { isM4myshot } = useContext(Context);
  const handleNavigate = (id) => {
    const section = document.getElementById(id);
    const position = section.getBoundingClientRect()?.top + window.scrollY - 90;

    if (position) {
      window.scrollTo({ top: position, behavior: "smooth" });
    }
  };
  return (
    // <div className={styles.container}>
    //   <div className={styles.coverDiv}>
    //     <p className="font_semibold_60_52_40 max-w-[800px]">
    //       Buy
    //       <span className="dmSerifRegularItalic"> memories </span>
    //       for your friends on their special day.
    //     </p>
    //     <p className="mt-[16px] sm:mt-[14px] font_normal_16 max-w-[500px]">
    //     Gift them the new kind of happiness
    //     </p>
    //     <div className={styles.coverImageDiv}>
    //       <div>
    //         <img src={imageLeft} alt="" className="object-cover" />
    //       </div>
    //       <div className="hidden lg:flex">
    //         <img src={imageRight} alt="" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div className={styles.coverContainer}>
    //   <div className={styles.backgroundContainerCover}>
    //     <div className={styles.pitchTitle}>
    //       <p className="font_semibold_120_62 text-center">
    //         {isM4myshot
    //           ? "AN EVENT WITHOUT MYSHOT MISSES THE FUN!"
    //           : "MEMSHOTS"}
    //       </p>
    //       {!isM4myshot && (
    //         <p className="font_normal_16 opacity-[0.8] max-w-[514px] text-center">
    //           The way you capture, organise, and share your most cherished
    //           memories.
    //         </p>
    //       )}
    //       {/* <div className={styles.pitchAbsolute}>Best one ever!</div> */}
    //     </div>

    //     <p className="absolute bottom-[20px] lg:bottom-[30px] left-[50%] translateX text-[#F6F6F6] opacity-[0.6] text-center">
    //       Scroll to explore more
    //     </p>
    //   </div>
    // </div>
    <div className={styles.coverContainer2}>
      <div className={styles.title2}>
        <p className="md:text-5xl text-3xl text-center max-w-[1280px] z-10">
          The way you capture, organise, and{" "}
          <span className="dmSerifRegularItalic text-[#0D0D0D]">share</span>{" "}
          your most cherished memories.{" "}
        </p>
        {/* <img
          src={cameraIcon}
          className="hidden md:block absolute w-[80px] md:w-[10%] left-[5%] bottom-[-20%] md:bottom-[-100%]"
        />
        <img
          src={photographerLogo}
          className="absolute w-[100px] md:w-[15%] right-[5%] bottom-[-20%] md:bottom-[-110%]"
        /> */}
      </div>
      <div className="flex hideScrollBar px-[16px] md:grid grid-cols-5 md:gap-[40px] gap-[20px] overflow-auto w-full md:w-[80%] lg:w-[70%]">
        <div
          className={styles.toolsCard}
          onClick={() => handleNavigate("face-detection")}
        >
          <img src={coverAiLogo} />
          <p>FACE DETECTED PHOTO DELIVERY</p>
        </div>
        <div
          className={styles.toolsCard}
          onClick={() => handleNavigate("invitation")}
        >
          <img src={coverInvitationLogo} />
          <p>DIGITAL EVENT INVITATION</p>
        </div>
        <div
          className={styles.toolsCard}
          onClick={() => handleNavigate("autograph")}
        >
          <img src={coverAutographLogo} />
          <p>DIGITAL SLAM BOOK</p>
        </div>
        <div
          className={styles.toolsCard}
          onClick={() => handleNavigate("shared-memory")}
        >
          <img src={sharedAlbumLogo} />
          <p>DIGITAL SHARED ALBUM</p>
        </div>
        <div
          className={styles.toolsCard}
          onClick={() => handleNavigate("album")}
        >
          <img src={coverAlbumLogo} />
          <p>DIGITAL WEDDING ALBUM </p>
        </div>
      </div>
    </div>
  );
}

export default Cover;
