import React, { useContext, useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router";
import AuthContext from "../../AuthContext";
import SignupPage from "../../Pages/Signup/SignupPage";
import axios from "axios";
import { Link } from "react-router-dom";
import coverCrmLogo from "../../Assets/coverCrmLogo.jpeg";

function CrmDetails({}) {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  const handleSubmit = () => {
    navigate("buynow");
  };

  return (
    <>
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div className={styles.container} id="crm">
        <div className={`bg-[#FFF5F0] md:bg-transparent ${styles.wishToolDiv}`}>
          <div className="w-full">
            {/* <p className="font_normal_40_32 dmSerifRegularItalic ">
              Share memories with special people!
            </p> */}
          </div>

          <div className=" gap-[40px] lg:mt-[10px] w-full flex flex-col lg:grid grid-cols-2 lg:gap-[60px]">
            <div>
              <img
                src={coverCrmLogo}
                alt=""
                className="w-full object-cover max-h-[70vh] rounded-[10px]"
              />
            </div>
            <div>
              <p className="font_medium_16_14 tracking-[.2em] bg-[#ff8989] text-white px-[14px] py-[8px] w-fit mb-[10px]">
                CRM
              </p>
              <p className={`font_normal_40_32 dmSerifRegularItalic `}>
                Streamline Your Business with Our All-in-One CRM
              </p>
              <p className="mt-[16px]  font_normal_16">
                Managing your events, improving client communication, and
                increasing bookings has never been easier! Our comprehensive CRM
                is designed to help businesses like yours stay organized, boost
                efficiency, and enhance customer satisfaction—all from one
                centralized platform.
              </p>
              <p className="mt-[12px]  font_normal_16">
                Keep track of every detail with an intuitive dashboard that
                allows you to assign tasks, set deadlines, and monitor progress
                effortlessly. From planning to execution, ensure your events run
                smoothly.
              </p>
              {/* <p className="font_semibold_18 mt-[40px]">{`Starting at ₹ ${sharedMemoriesBaseAmount}`}</p> */}
              <div className={styles.wishToolPricing}>
                {/* <p className="font_bold_24 mt-[40px]">Ready to dive in?</p> */}
                <div className={styles.wishToolPricingSection}>
                  {/* <p className="dmSerif_italic_blue font_normal_24">
                Try a free demo today or buy it.
              </p> */}
                  {/* <div className="flex gap-[12px]">
                    <Link
                      to={
                        "https://app.memshots.com/177corporatetour/sharedalbum?from=memshotscorporates"
                      }
                    >
                      <button className={styles.tryDemoButton}>
                        View Sample
                      </button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrmDetails;
