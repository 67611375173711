import React from "react";
import styles from "../styles.module.css";
import autographRestaurants from "../../../Assets/Images/Pitch/autographRestaurants.png";
import sharedAlbumRestaurants from "../../../Assets/Images/Pitch/sharedAlbumRestaurants.png";
import faceDetectionRestaurants from "../../../Assets/Images/Pitch/faceDetectionRestaurants.png";
import { Link, useNavigate } from "react-router-dom";
import goToIcon from "../../../Assets/Images/Home/goToIcon.svg";

function ProductsForRestaurants() {
  const weddingAutographHighlights = [
    "Lasting Memories",
    "Celebrating Guest Memories",
    "Best Ever Review Book",
  ];
  const sentOffAutographHighlights = [
    "Preserve Guest Memories",
    "Nostalgic Experience",
    "Personalized Guest Experiences",
  ];
  const classmatesAutographHighlights = [
    "Effortless Smile Capture",
    "Seamless Guest Access",
    "Comprehensive Facial Recognition",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.productsDiv}>
        <ProductLists
          image={autographRestaurants}
          heading={"Review Autograph"}
          subHeading={
            "The Review Autograph allows diners to leave personalized, digital signatures and heartfelt messages, making each review unique and memorable. Elevate your restaurant's review collection process with the Best Ever Review Book and turn every piece of feedback into a cherished digital keepsake."
          }
          highlights={weddingAutographHighlights}
          colour={"#FF6D3A"}
          sampleUrl={
            "https://app.memshots.com/159silverjubilee/memories/allWishes?from=memshotsrestaurants"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={sharedAlbumRestaurants}
          heading={"Food Fest Memories Album"}
          subHeading={
            "The Food Fest Memories Album lets attendees and guests share their photos and messages, creating a personalized keepsake. Hotels can preserve event photos as a digital album, capturing the joy and flavors of the fest. This enhances guest experiences and fosters lasting memories of the celebration."
          }
          highlights={sentOffAutographHighlights}
          colour={"#9747FF"}
          sampleUrl={
            "https://app.memshots.com/158foodfest/sharedalbum?from=memshotsrestaurants"
          }
          purchaseUrl={"/buynow?from=sharedMemories"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Shared Memories"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={faceDetectionRestaurants}
          heading={"AI Based Face Delivery"}
          subHeading={
            "Simplify event experiences at your restaurant with our AI-Based Photo Delivery Tool. With just a selfie, guests effortlessly receive all event photos, making memorable moments easily accessible to everyone. Enhance guest satisfaction and streamline photo access for all your events."
          }
          highlights={classmatesAutographHighlights}
          colour={"#098C55"}
          sampleUrl={"https://photographers.memshots.com"}
          buttonTextSample={"Explore"}
        />
      </div>
    </div>
  );
}

export default ProductsForRestaurants;

function ProductLists({
  image,
  heading,
  subHeading,
  highlights,
  colour,
  sampleUrl,
  purchaseUrl,
  buttonTextSample,
  buttonTextUrl,
  children,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.productCards}>
        <img src={image} alt="" />
        <div className="flex flex-col justify-between gap-[40px]">
          <div>
            <p className="font_semibold_32_24 text-[#161616]">{heading}</p>
            <p className="mt-[16px] lg:mt-[24px]">{subHeading}</p>
            <ul className={styles.productsGridContainer}>
              {highlights.map((obj, i) => (
                <li
                  style={{
                    backgroundColor: `${colour}`,
                  }}
                  key={i}
                >
                  {obj}
                </li>
              ))}
            </ul>
          </div>
          <div className=" gap-[24px] items-end hidden xl:flex">
            <p className="dmSerif_italic_blue font_normal_24">
              {/* {buttonTextSample === "Explore"
                ? "Visit memshots photographers"
                : "View a sample"} */}
            </p>
            <Link to={sampleUrl}>
              {buttonTextSample === "Explore" ? (
                <>
                  {/* <button
                    className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                  >
                    <p>Explore</p>
                    <img src={goToIcon} alt="" />
                  </button> */}
                </>
              ) : (
                <button className={styles.tryDemoButton}>
                  {buttonTextSample}
                </button>
              )}
            </Link>
            {/* {buttonTextUrl && (
              <button
                onClick={() => navigate(`${purchaseUrl}`)}
                className={styles.tryDemoButton}
              >
                {buttonTextUrl}
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] xl:hidden mb-[40px]">
        <p className="dmSerif_italic_blue font_normal_24 ">
          {/* {buttonTextSample === "Explore"
            ? "Visit memshots photographers"
            : "View a sample"} */}
        </p>
        <div className="flex flex-col sm:flex-row gap-[12px]">
          <Link to={sampleUrl}>
            {buttonTextSample === "Explore" ? (
              <>
                {/* <button
                  className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                >
                  <p>Explore</p>
                  <img src={goToIcon} alt="" />
                </button> */}
              </>
            ) : (
              <button className={styles.tryDemoButton}>
                {buttonTextSample}
              </button>
            )}
          </Link>
          {/* {buttonTextUrl && (
            <button
              onClick={() => navigate(`${purchaseUrl}`)}
              className={styles.tryDemoButton}
            >
              {buttonTextUrl}
            </button>
          )} */}
        </div>
      </div>
      {children}
    </div>
  );
}
