import React, { useContext } from "react";
import styles from "./home.module.css";
import InvitationBgImage from "../../Assets/Images/Home/InvitationBgImage.png";
import { Link, useNavigate } from "react-router-dom";
import Context from "../../Context";

function Invitation() {
  const navigate = useNavigate();
  const { isM4myshot } = useContext(Context);

  return (
    <div className={styles.container} id="invitation">
      <div className={`bg-[#E3F7EB] md:bg-transparent ${styles.wishToolDiv}`}>
        <div className="w-full">
          {/* <p className="font_normal_40_32 dmSerifRegularItalic ">
            Plan events with customized template
          </p> */}
        </div>
        <div className=" gap-[40px] lg:mt-[10px] w-full flex flex-col-reverse lg:grid grid-cols-2 lg:gap-[60px]">
          <div>
            <p className="font_medium_16_14 tracking-[.2em] bg-[#4cca88] text-white px-[14px] py-[8px] w-fit mb-[10px]">
              ONLINE INVITATION
            </p>
            <p className="font_normal_40_32 dmSerifRegularItalic ">
              Plan events with customized template
            </p>
            <p className="mt-[16px] font_normal_16">
              Planning an event has never been easier with our user-friendly
              online invitation service.
            </p>
            <p className="mt-[12px] font_normal_16">
              Design unique invitations that reflect the theme and style of your
              event effortlessly. With our platform, you can provide guests with
              all the necessary information they need to attend, including date,
              time, location, and any special instructions. Say goodbye to the
              stress of event planning and try our online invitation service
              today!{" "}
            </p>

            {/* <p className="font_semibold_18 mt-[40px]">Starting at ₹ 1000</p> */}

            <div className={styles.wishToolPricing}>
              {/* <p className="font_bold_24">Ready to dive in?</p> */}
              <div className={styles.wishToolPricingSection}>
                {/* <p className="dmSerif_italic_blue font_normal_24">
              {isM4myshot
                ? "Try a free demo today"
                : "Try a free demo today or buy it."}
            </p> */}
                <div className="flex gap-[12px]">
                  <Link
                    to={
                      isM4myshot
                        ? "https://invitation.m4myshot.com/inv45daniel&charlo?from=m4myshotdemo"
                        : "https://invitation.memshots.com/inv45daniel&charlo?from=memshotsdemo"
                    }
                  >
                    <button className={styles.tryDemoButton}>
                      View Sample
                    </button>
                  </Link>
                  {/* {!isM4myshot && (
                    <button
                      onClick={() => {
                        navigate("/buyinvitation");
                      }}
                      className={styles.buyNowButton}
                    >
                      Buy Now
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <img
              src={InvitationBgImage}
              alt=""
              className="w-full object-cover max-h-[70vh] rounded-[10px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invitation;
