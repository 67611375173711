import React, { useContext } from "react";
import styles from "./home.module.css";
import faceDetectionImg from "../../Assets/Images/Home/faceDetectionImg.png";
import faceDetectionImgM4 from "../../Assets/Images/Home/faceDetectionImgM4.jpeg";
import birthdaypartyFDIcon from "../../Assets/Images/Home/birthdaypartyFDIcon.svg";
import corporateEventFDIcon from "../../Assets/Images/Home/corporateEventFDIcon.svg";
import privateEventFDIcon from "../../Assets/Images/Home/privateEventFDIcon.svg";
import weddingFDIcon from "../../Assets/Images/Home/weddingFDIcon.svg";
import goToIcon from "../../Assets/Images/Home/goToIcon.svg";
import Context from "../../Context";
import aiBannerWeb from "../../Assets/aiBannerWeb.png";
import aiBannerMob from "../../Assets/aiBannerMob.png";
import CardCarousel from "../CardCarousel/CardCarousel";

function FaceDetection() {
  const { isM4myshot } = useContext(Context);
  const imageRender = (innerWidth) => {
    if (innerWidth <= 650) {
      return aiBannerMob;
    } else {
      return aiBannerWeb;
    }
  };
  const facts = {
    heading: "How It Works",
    description: "",
    cards: [
      {
        heading: "Step 1: Create Event & Get QR Code",
        description:
          "Create an event in the CRM and download the unique QR code.",
        graphics:
          "https://storage.googleapis.com/goviral_home_data/homeMedia/media/memshotHome/1.jpeg",
        bgColor: "#E4F8FF",
      },
      {
        heading: "Step 2: Place QR Code",
        description:
          "Display the QR code at the venue where attendees can easily access it.",
        graphics:
          "https://storage.googleapis.com/goviral_home_data/homeMedia/media/memshotHome/2.jpeg",
        bgColor: "#F1F6FC",
      },
      {
        heading: " Step 3: Scan & Register",
        description:
          "Guests scan the QR code to pre-register their faces, ensuring they automatically receive their event photos via WhatsApp and Email.",
        graphics:
          "https://storage.googleapis.com/goviral_home_data/homeMedia/media/memshotHome/3.jpeg",
        bgColor: "#FEF4F5",
      },
      {
        heading: "Step 4: Image Upload",
        description:
          "Upload event photos to the CRM, where they will be processed and published in the gallery.",
        graphics:
          "https://storage.googleapis.com/goviral_home_data/homeMedia/media/memshotHome/4.jpeg",
        bgColor: "#F1F6FC",
      },
      {
        heading: "Step 5: Get Event Photos",
        description:
          "Attendees receive a personalized link to their photos via WhatsApp and Email.",
        graphics:
          "https://storage.googleapis.com/goviral_home_data/homeMedia/media/memshotHome/5.jpeg",
        bgColor: "#F1F6FC",
      },
    ],
  };

  return (
    <div className={styles.container} id="face-detection">
      <div className={styles.faceDetectionDiv}>
        <div className={styles.faceDetectionTitle}>
          <p className="font_semibold_72_32">AI Photo Delivery</p>
          <p className="font_normal_16 opacity-[0.8] max-w-[436px]">
            Capture smiles effortlessly! Our tool recognizes every face,
            ensuring no moment is missed. Say hello to memories that warm your
            heart for years!
          </p>

          {/* <div className={styles.faceDetectionAbsolute}>
            <img src="shootmatesLogo.png" alt="" />
            <p>Memshots for Photographers</p>
          </div> */}
        </div>

        <div className="mt-[40px]">
          <img src={imageRender(window.innerWidth)} alt="AI Photo Delivery" />
        </div>
        {/* <div className={styles.faceDetectionContent}>
          <div className={styles.faceDetectionCardSection}>
            <div
              style={{
                background: "#17AB59",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={weddingFDIcon} alt="" />
              <p className={styles.cardHeading}>Wedding Events</p>
              <p className={styles.cardSubHeading}>
                Effortlessly preserve moments with our selfie-powered photo
                capture.
              </p>
            </div>
            <div
              style={{
                background: "#DE632E",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={birthdaypartyFDIcon} alt="" />
              <p className={styles.cardHeading}>Birthday Parties</p>
              <p className={styles.cardSubHeading}>
                Easily identify every smiling face in photos by simply snapping
                a selfie.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-[#161616]">
            <img
              src={isM4myshot ? faceDetectionImgM4 : faceDetectionImg}
              alt=""
            />
            <p className="font_semibold_24_21 opacity-[0.8] text-center lg:mt-[18px] max-w-[365px]">
              Your Memories and Moments come alive
            </p>
          </div>
          <div className={styles.faceDetectionCardSection}>
            <div
              style={{
                background: "#2727E6",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={corporateEventFDIcon} alt="" />
              <p className={styles.cardHeading}>Corporate Events</p>
              <p className={styles.cardSubHeading}>
                Impress at your corporate event with our reliable face detection
                feature.
              </p>
            </div>
            <div
              style={{
                background: "#9407A1",
              }}
              className={styles.faceDetectionCard}
            >
              <img src={privateEventFDIcon} alt="" />
              <p className={styles.cardHeading}>Private Events</p>
              <p className={styles.cardSubHeading}>
                Gather party memories seamlessly with friends through quick
                selfies.
              </p>
            </div>
          </div>
        </div> */}
        {/* {!isM4myshot && (
          <div className={styles.albumButtonsSection}>
            <p className="font_bold_24 max-w-[580px]">Ready to dive in?</p>
            <div className="flex gap-[20px]">
              <a
                href="https://photographers.memshots.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                >
                  <span>Explore</span>
                  <img src={goToIcon} alt="" />
                </button>
              </a>
              <a
                href="https://photographers.memshots.com/aiphotosortingdemo"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.buyNowButton}>Try Demo</button>
              </a>
            </div>
          </div>
        )} */}
        <div className="w-full">
          <div className="pt-[60px] md:[60px] w-full">
            <div className="mb-[20px] md:mb-[30px]">
              <h2 className="w-[90%] md:w-[40%] text-[#000] font_semibold_32_24">
                {facts?.heading}
              </h2>
              <h5 className="w-[80%] md:w-[40%] mt-[7px] md:mt-[16px]">
                {facts?.description}
              </h5>
            </div>
            <CardCarousel items={facts?.cards} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceDetection;
