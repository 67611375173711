import React from "react";
import styles from "../styles.module.css";
import weddingAutograph from "../../../Assets/Images/Pitch/weddingAutograph.png";
import sentOffAutograph from "../../../Assets/Images/Pitch/sentOffAutograph.png";
import classmatesAutograph from "../../../Assets/Images/Pitch/classmatesAutograph.png";
import { Link, useNavigate } from "react-router-dom";

function ProductsForGifts() {
  const weddingAutographHighlights = [
    "Thoughtful Gesture",
    "Meaningful Contribution",
    "Personalized Experience",
  ];
  const sentOffAutographHighlights = [
    "Enduring Memories",
    "Celebrating their Contributions",
    "Honoring Their Contributions",
  ];
  const classmatesAutographHighlights = [
    "Preserves Memories",
    "Nostalgic Experience",
    "Keepsake for Life",
    "Connection with Peers",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.productsDiv}>
        <ProductLists
          image={weddingAutograph}
          heading={"Wedding Autograph"}
          subHeading={
            "The Memshots Wedding Autograph isn't just a gift; it's a heartfelt gesture, demonstrating care for the couple's special day. Beyond capturing moments, it preserves heartfelt messages, crafting a personalized keepsake reflecting the love and support surrounding their journey together."
          }
          highlights={weddingAutographHighlights}
          colour={"#FF6D3A"}
          sampleUrl={
            "https://app.memshots.com/154pranav&kalyan/memories/allWishes?from=memshotsgifts"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={sentOffAutograph}
          heading={"Farewell Autograph"}
          subHeading={
            "Saying goodbye is tough, but Memshots Farewell Autograph makes it special. It captures signatures, messages, and memories, offering closure and celebrating each person's impact. It's a heartfelt way to make farewells memorable and honor contributions to the workplace or community."
          }
          highlights={sentOffAutographHighlights}
          colour={"#9747FF"}
          sampleUrl={
            "https://app.memshots.com/155farewellautogr/memories/allWishes?from=memshotsgifts"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>
        <ProductLists
          image={classmatesAutograph}
          heading={"Classmates Autograph"}
          subHeading={
            "Memshots Classmates Digital Autograph preserves school memories, featuring messages and well-wishes from peers. It's a nostalgic keepsake, fostering connections with classmates and ensuring lifelong memories are cherished."
          }
          highlights={classmatesAutographHighlights}
          colour={"#098C55"}
          sampleUrl={
            "https://app.memshots.com/153classmatesauto/memories/allWishes?from=memshotsgifts"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        />
      </div>
    </div>
  );
}

export default ProductsForGifts;

function ProductLists({
  image,
  heading,
  subHeading,
  highlights,
  colour,
  sampleUrl,
  purchaseUrl,
  buttonTextSample,
  buttonTextUrl,
  children,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.productCards}>
        <img src={image} alt="" />
        <div className="flex flex-col justify-between gap-[40px]">
          <div>
            <p className="font_semibold_32_24 text-[#161616]">{heading}</p>
            <p className="mt-[16px] lg:mt-[24px]">{subHeading}</p>
            <ul className={styles.productsGridContainer}>
              {highlights.map((obj, i) => (
                <li
                  style={{
                    backgroundColor: `${colour}`,
                  }}
                  key={i}
                >
                  {obj}
                </li>
              ))}
            </ul>
          </div>
          <div className=" gap-[24px] items-end hidden xl:flex">
            {/* <p className="dmSerif_italic_blue font_normal_24">View a sample</p> */}
            <Link to={sampleUrl}>
              <button className={styles.tryDemoButton}>
                {buttonTextSample}
              </button>
            </Link>
            {/* {buttonTextUrl && (
              <button
                onClick={() => navigate(`${purchaseUrl}`)}
                className={styles.tryDemoButton}
              >
                {buttonTextUrl}
              </button>
            )}*/}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] xl:hidden mb-[40px]">
        <p className="dmSerif_italic_blue font_normal_24 ">View a sample</p>
        <div className="flex flex-col sm:flex-row gap-[12px]">
          <Link to={sampleUrl}>
            <button className={styles.tryDemoButton}>{buttonTextSample}</button>
          </Link>
          {/* {buttonTextUrl && (
            <button
              onClick={() => navigate(`${purchaseUrl}`)}
              className={styles.tryDemoButton}
            >
              {buttonTextUrl}
            </button>
          )} */}
        </div>
      </div>
      {children}
    </div>
  );
}
