import React, { useContext, useEffect, useRef, useState } from "react";
import hamburgerIconBlack from "../Assets/Images/hamburgerIconBlack.svg";
import hamburgerIconWhite from "../Assets/Images/hamburgerIconWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import axios from "axios";
import styles from "./Home/home.module.css";
import closeIcon from "../Assets/Images/Home/x.png";
import Context from "../Context";

function Hamburger({
  setWishSelected,
  setSelectedWishpackage,
  handleSubmit,
  currentUser,
  googleSignOut,
  setPopup,
  packagePricing,
  background,
  setFreeTrial,
}) {
  const [dropdown, setDropdown] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();
  const { isM4myshot } = useContext(Context);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <div>
      <div className="cursor-pointer " ref={modalRef}>
        <img
          onClick={(e) => {
            setDropdown((prevState) => {
              e.stopPropagation();
              return !prevState;
            });
          }}
          src={
            dropdown
              ? closeIcon
              : background === "transparent"
              ? hamburgerIconWhite
              : hamburgerIconBlack
          }
          alt="Icon"
          className={` ${dropdown ? styles.closeIcon : styles.hamburgerIcon}`}
        />
        {dropdown && (
          <div className={styles.hamburger}>
            <div className="flex flex-col items-start gap-[45px]">
              {/* <button
                onClick={() => {
                  navigate("/poster");
                }}
                className="font_medium_21 text-[#161616]"
              >
                Poster Memories
              </button> */}
              {/* <button
                onClick={() => {
                  navigate("/pricing");
                }}
                className="font_medium_21 text-[#161616] w-full text-left"
              >
                Pricing
              </button> */}
              <button className="font_medium_21 text-[#161616] w-full text-left">
                <Link to="/tutorials">Tutorials</Link>
              </button>
              <button className="font_medium_21 text-[#161616] w-full text-left">
                <Link to="/clients">Clients</Link>
              </button>
              <button className="font_medium_21 text-[#161616] w-full text-left">
                <Link to="/contact">Contact</Link>
              </button>
              <button
                onClick={() => setFreeTrial(true)}
                className="font_medium_21 text-[#161616] w-full text-left"
              >
                Start Free Trial
              </button>
              {/* <button
                onClick={() => {
                  setWishSelected(true);
                  setSelectedWishpackage(packagePricing?.wish[0]);
                  handleSubmit();
                }}
                className="font_medium_21 text-[#161616]"
              >
                Buy Now
              </button> */}
              {!isM4myshot && (
                <>
                  {currentUser && (
                    <button
                      onClick={() => {
                        navigate("/userprofile");
                      }}
                      className="font_medium_21 text-[#161616] w-full text-left"
                    >
                      Profile
                    </button>
                  )}
                </>
              )}
            </div>
            {!isM4myshot && (
              <>
                <div className="horizontalSeperator mt-[45px] mb-[20px]"></div>
                <button
                  onClick={() => {
                    currentUser ? googleSignOut() : setPopup(true);
                  }}
                  className="font_medium_18 text-[#F96149] opacity-[0.8] w-full text-left"
                >
                  {currentUser ? "Sign out" : "Sign in"}
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Hamburger;
