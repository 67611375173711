import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../AuthContext";
import Context from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import FooterShort from "../Components/FooterShort";
import styles from "./UserProfile.module.css";
import arrowRightGrey from "../Assets/Images/arrowRightGrey.svg";
import sharedAlbumIcon from "../Assets/Images/sharedAlbumIcon.svg";
import onlineWishesIcon from "../Assets/Images/onlineWishesIcon.svg";
import facedetectionIcon from "../Assets/Images/facedetectionIcon.svg";
import selectionToolIcon from "../Assets/Images/selectionToolIcon.svg";
import InvitationIcon from "../Assets/Images/InvitationIcon.svg";
import axios from "axios";
import PageLoading from "../Components/PageLoading/PageLoading";
import firstMemshots from "../Assets/Images/firstMemshots.svg";
import newUserIcon from "../Assets/Images/newUserIcon.svg";
import NavbarNew from "../Components/NavbarNew";

function UserProfile() {
  const { currentUser, googleSignOut, userIdToken, loading } =
    useContext(AuthContext);
  const { open, setOpen } = useContext(Context);
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState("ALBUM_OWNER");
  const token = userIdToken || currentUser?.accessToken;
  const [userdata, setUserData] = useState({});
  const navigate = useNavigate();
  const [apiLoading, setApiLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false)

  useEffect(() => {
    if (currentUser) {
      getuserdata();
    }
    if (!currentUser && !loading) {
      navigate("/");
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getuserdata = async () => {
    setApiLoading(true);
    try {
      let config = {
        method: "GET",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshot/album/useralbums`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setUserData(response?.data?.data);
      setApiLoading(false);
    } catch (err) {
      console.log(err);
      setApiLoading(false);
    }
  };


  const handleRetryPayment = async (albumId) => {
    setRetryLoading(true)
    try {
      let config = {
        method: "POST",
        url: `https://asia-south1-memshots-380011.cloudfunctions.net/memshotAlbum/api/memshots/sharingtool/initialise_payment/registration/${albumId}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        window.location.href =
          response?.data?.paymentPayload?.data?.instrumentResponse?.redirectInfo?.url;
        setRetryLoading(false)
      }
    } catch (err) {
      console.log(err)
      setRetryLoading(false)
    }
  }

  return (
    <div className="containFull">
      <NavbarNew />
      <div className={styles.container}>
        <div className="w-full h-full max-w-[1440px]">
          <div className="w-full flex justify-between items-center mt-[20px] lg:mt-[60px]">
            <div className="flex items-center gap-[16px]">
              <img
                src={
                  currentUser?.photoURL ? currentUser?.photoURL : newUserIcon
                }
                alt=""
                className={styles.profilePicture}
              />
              <div className="flex flex-col items-start">
                <p className="font_bold_21 text-[#2D2C2C]">
                  {currentUser?.displayName}
                </p>
                <p
                  className={`${styles.signOutButton} mobileOnly`}
                  onClick={() => {
                    googleSignOut();
                  }}
                >
                  Sign out
                </p>
              </div>
            </div>
            <p
              className={`${styles.signOutButton} desktopOnly`}
              onClick={() => {
                googleSignOut();
              }}
            >
              Sign out
            </p>
          </div>
          <div className={styles.tabs}>
            <div className={`${styles.tabsHeader} no-scrollbar `}>
              <div
                style={{
                  color: activeFilter === "ALBUM_OWNER" ? "#3171F6" : "#161616",
                }}
                className={styles.tabsFilter}
                onClick={() => {
                  setActiveFilter("ALBUM_OWNER");
                }}
              >
                Your Memshots
              </div>
              <div
                style={{
                  color: activeFilter === "ALBUM_USER" ? "#3171F6" : "#161616",
                }}
                className={styles.tabsFilter}
                onClick={() => {
                  setActiveFilter("ALBUM_USER");
                }}
              >
                Shared with you
              </div>
            </div>
          </div>
          <div className="horizontalSeperator"></div>
          {apiLoading ? (
              <PageLoading />
          ) : userdata?.userAlbums?.length === 0 ? (
            <div className="py-[20px] w-full flex flex-col justify-center items-center my-[50px] lg:my-[100px]">
              <img src={firstMemshots} alt="" />
              <p className="font_bold_21">Create your first Memshots</p>
              <p className="mt-[12px] font_normal_13 max-w-[340px]">
                Embark on your unforgettable journey with Memshots: Capture,
                cherish, and share your very first memories.
              </p>
              <button
                onClick={() => {
                  navigate("/");
                }}
                className={styles.goHomeButton}
              >
                Go home and create
              </button>
            </div>
          ) : (
            <div className="py-[20px] flex flex-col gap-[12px]">
              {userdata?.userAlbums?.map((obj, i) => {
                if (activeFilter === obj?.role) {
                  return (
                    <div key={i} className={styles.ownCard}>
                      <div className="flex justify-between">
                        <div className="flex gap-[11px] lg:gap-[32px] flex-col lg:flex-row">
                          <p className="font_semibold_21">{obj?.albumName}</p>
                          {obj?.role === "ALBUM_OWNER" ? (
                            <div
                              className={`${styles.createdByButton} font_normal_12`}
                            >
                              Created by{" "}
                              <span className="font_semibold_12">You</span>
                            </div>
                          ) : (
                            <div
                              className={`${styles.createdByButton} font_semibold_12 `}
                            >
                              {" "}
                              <span className="font_normal_12">Shared</span>
                            </div>
                          )}
                        </div>
                        <div className="lg:flex items-center hidden ">
                          {obj?.paymentStatus &&
                            obj?.paymentStatus === "PAYMENT_SUCCESS" ? (
                            <div className="gap-[4px] flex items-center">
                              <a href="https://admin.memshots.com/" target="_blank" rel="noreferrer">
                                <p className="font_medium_14 text-[#787878] text-nowrap">
                                  View Dashboard
                                </p>
                              </a>
                              <img src={arrowRightGrey} alt="" />
                            </div>
                          ) : (
                            <div className="gap-[14px] flex items-center">
                              <p className="font_medium_13 opacity-[0.8]">
                                We couldn’t process your payment
                              </p>
                              <button
                                disabled={retryLoading}
                                onClick={() => handleRetryPayment(obj?.albumId)}
                                className={styles.retryPayment}>
                                {retryLoading ? "Please wait" : "Retry Payment"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.ownCardSection}>
                        {/* Shared Memories section */}
                        {obj?.sharedMemPackage && (
                          <div className={styles.ownCardInner}>
                            <div className="flex  items-center">
                              <img src={sharedAlbumIcon} alt="" />
                              <p className="font_medium_16 text-[#2A2A2ACC] ml-[10px] lg:ml-[7px]">
                                Shared Memories Album
                              </p>
                              <div
                                style={{
                                  background:
                                    obj?.sharedMemPackage?.packageBgColor,
                                }}
                                className={styles.selectedPackageInd}
                              >
                                {obj?.sharedMemPackage?.package}
                              </div>
                            </div>
                            <div>
                              <a href="https://admin.memshots.com/" target="_blank" rel="noreferrer">
                                <img src={arrowRightGrey} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                        {/* Wishes section */}
                        {obj?.wishesPackage && (
                          <div className={styles.ownCardInner}>
                            <div className="flex items-center">
                              <img src={onlineWishesIcon} alt="" />
                              <p className="font_medium_16 text-[#2A2A2ACC] ml-[10px] lg:ml-[7px]">
                                Digital Wedding Autograph
                              </p>
                              <div
                                style={{
                                  background:
                                    obj?.wishesPackage?.packageBgColor,
                                }}
                                className={styles.selectedPackageInd}
                              >
                                {obj?.wishesPackage?.package}
                              </div>
                            </div>
                            <div>
                              <a href="https://admin.memshots.com/" target="_blank" rel="noreferrer">
                                <img src={arrowRightGrey} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                        {/* Invitation section */}
                        {obj?.albumType === "INVITATION" && (
                          <div className={styles.ownCardInner}>
                            <div className="flex items-center">
                              <img src={InvitationIcon} alt="" />
                              <p className="font_medium_16 text-[#2A2A2ACC] ml-[10px] lg:ml-[7px]">
                                Invitation
                              </p>
                            </div>
                            <div>
                              <a href="https://admin.memshots.com/" target="_blank" rel="noreferrer">
                                <img src={arrowRightGrey} alt="" />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      {(obj?.albumType === "PHOTOTOOL" || obj?.albumType === "INVITATION") && (
                        <div className="flex justify-center gap-[4px] items-center lg:hidden mt-[16px]">
                          {obj?.paymentStatus &&
                            obj?.paymentStatus === "PAYMENT_SUCCESS" ? (
                            <div className="gap-[4px] flex items-center">
                              <p className="font_medium_14 text-[#787878] text-nowrap">
                                View Dashboard
                              </p>
                              <img src={arrowRightGrey} alt="" />
                            </div>
                          ) : (
                            <div className="gap-[14px] flex flex-col items-center">
                              <p className="font_medium_13 opacity-[0.8]">
                                We couldn’t process your payment
                              </p>
                              <button
                                disabled={retryLoading}
                                onClick={() => handleRetryPayment(obj?.albumId)}
                                className={styles.retryPayment}>
                                {retryLoading ? "Please wait" : "Retry Payment"}
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default UserProfile;
