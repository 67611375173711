import React, { useEffect, useState } from "react";
import "./TrialPopup.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import axios from "axios";
import styles from "./TrialPopup.module.css";
import { isEmpty } from "lodash";
import Lottie from "react-lottie-player";
import animation from "../../Assets/doneAnimation.json";

function TrialPopup({ setFreeTrial }) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const trialData = [
    {
      _id: 7,
      packageName: "Trial",
      price: 0,
      storageInGb: 5,
      services: [
        {
          name: "Photo compression",
          pricing: "free",
          count: "unlimited",
        },
        {
          name: "Photo selection",
          pricing: "free",
          count: "unlimited",
        },
        {
          name: "Ai photo delivery",
          pricing: "free",
          price: 0,
          count: "unlimited",
        },
      ],
      packageType: "TRIAL",
      storageTenureInMonth: "",
      storageInBytes: 5368709120,
      trialUsed: true,
      storageTenureInDays: 14,
    },
  ];
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [id]: value }));
  };
  const handleSubmit = () => {
    let errors = {};
    inputfields?.forEach((field) => {
      if (field?.required) {
        if (!formValues[field?.id]) {
          errors[field?.id] = field.errorText;
        }
      }
    });
    setFormErrors(errors);
    if (isEmpty(errors)) {
      requstTrial();
    }
  };
  const requstTrial = async () => {
    try {
      setLoading(true);
      let config = {
        method: "POST",
        url: "https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/memshots/request/trialpackage",
        data: formValues,
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        setSuccess(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  const inputfields = [
    {
      name: "Name",
      id: "name",
      placeholder: "Please Enter Your Name",
      required: true,
      errorText: "Please enter you name",
    },
    {
      name: "Email",
      id: "email",
      placeholder: "Please Enter Your Email",
      errorText: "Please Enter Your Email",
      required: true,
    },
    {
      name: "Phone Number",
      id: "phone",
      placeholder: "Please Enter Your Phone Number",
      errorText: "Please Enter Your Phone Number",
      required: true,
    },
  ];

  return (
    <PopupWrapper visible={true}>
      <div
        className={`bg-[#fff]  popUpShadow h-fit w-full max-w-[500px] rounded-[10px] flex flex-col trialPop ${
          success ? "mt-[10%]" : "my-[2%]"
        }`}
      >
        {success ? (
          <div className="flex flex-col items-center justify-center h-full gap-[30px]">
            <Lottie
              loop={false}
              animationData={animation}
              play
              style={{ width: 50, height: 50 }}
            />
            <p className="headTrial">Submitted! We'll get back to you soon.</p>
            <button
              disabled={loading}
              className="continueButton"
              onClick={() => {
                setFreeTrial(false);
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <>
            <div className="flex w-full border-opacity-20 justify-between pb-[20px] items-start border-b-[1px] border-[#475467] ">
              <div className=" flex items-center gap-[12px]">
                <button className=" headDiv">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="#373CBD" />
                  </svg>
                </button>
                <div>
                  <p className="FreeTrial flex items-center gap-[4px]">
                    Free Trial{" "}
                    <span className="days">
                      {trialData?.[0]?.storageTenureInDays} Days
                    </span>
                  </p>
                  <p className="mt-[9px]">
                    Total Storage{" "}
                    <span className="font-[600]">
                      {trialData?.[0]?.storageInGb}GB
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="border-[1px] border-[#475467] mt-[20px] rounded-[10px] border-opacity-20  justify-start items-start flex flex-col  p-[20px]">
              <p className="headTrial">
                Free Trial for {trialData?.[0]?.storageTenureInDays} Days
              </p>
              <p className="demo">Demo version</p>

              {trialData?.[0]?.services?.map((item, i) => (
                <div
                  className={`flex items-center gap-[4px] ${
                    i === 0 ? "mt-[20px]" : "mt-[12px]"
                  } `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z"
                      fill="#373CBD"
                    />
                    <path
                      d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9166 8.37503 12.8L6.0167 10.4416C5.77503 10.2 5.77503 9.79996 6.0167 9.55829C6.25837 9.31663 6.65837 9.31663 6.90003 9.55829L8.8167 11.475L13.1 7.19163C13.3417 6.94996 13.7417 6.94996 13.9834 7.19163C14.225 7.43329 14.225 7.83329 13.9834 8.07496L9.25837 12.8C9.1417 12.9166 8.98337 12.9833 8.8167 12.9833Z"
                      fill="white"
                    />
                  </svg>
                  <p className="itemName">{item?.name}</p>
                </div>
              ))}
            </div>
            <div>
              {inputfields.map((field) => (
                <div>
                  <div className={styles.form}>
                    <div className="flex items-center relative">
                      <p className={`mt-[10px] `}>{field?.name}</p>
                    </div>
                    <input
                      type="text"
                      id={field?.id}
                      className={` ${
                        formErrors && formErrors[field?.id] && styles.inputError
                      }`}
                      onChange={handleChange}
                      value={formValues && formValues[field?.id]}
                      placeholder={field?.placeholder}
                    />
                  </div>
                  {formErrors && formErrors[field?.id] && (
                    <span className={styles.error}>
                      {formErrors[field?.id]}
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div
              className={`flex items-center gap-[12px] justify-center sticky bottom-0 bg-white py-[16px]`}
            >
              <button
                className="cancelButtonPop"
                disabled={loading}
                onClick={() => setFreeTrial(false)}
              >
                Cancel
              </button>
              <button
                disabled={loading}
                className="continueButton"
                onClick={handleSubmit}
              >
                {loading ? "Loading" : "Continue"}
              </button>
            </div>
          </>
        )}
      </div>
    </PopupWrapper>
  );
}

export default TrialPopup;
