import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Cover from "../Components/Pitch/Gifts/Cover";
import CoverImage from "../Components/Pitch/Gifts/CoverImage";
import Testimonials from "../Components/Pitch/Gifts/Testimonials";
import Products from "../Components/Pitch/Gifts/ProductsForGifts";
import backToTop from "../Assets/Images/Home/backToTop.svg";
import Features from "../Components/Pitch/Gifts/Features";
import FooterShort from "../Components/FooterShort";
import imageLeft from "../Assets/Images/Pitch/corporatesGrpahicsImgLeft.png";
import imageRight from "../Assets/Images/Pitch/corporatesGrpahicsImgRight.png";
import ProductsForCorporates from "../Components/Pitch/Gifts/ProductsForCorporates";
import NavbarNew from "../Components/NavbarNew";
import SEO from "../Components/SEO/SEO";

function PitchForCorporates() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="containFull cursor-default">
      <SEO
        title="Memshots for Corporates: AI Photo Delivery, Digital Albums, & More"
        description="Elevate your corporate events with Memshots! Our innovative solution ensures unforgettable experiences. Seamlessly capture every moment, from team-building activities to conferences, leaving a lasting impression. With Memshots, turn ordinary events into extraordinary memories, fostering team spirit and leaving participants inspired."
        keywords="photo sharing, memories, gifts, custom albums, AI Face Detection, Online Invitations, Online Photo Albums, Photo Selection, Digital Autographs, corporates"
        url="https://memshots.com/corporates"
        image="https://memshots.com/logo.png"
      />
      <NavbarNew />
      <Cover
        title={"Memshots for Corporates"}
        content={`Elevate your corporate events with Memshots! Our innovative solution ensures unforgettable experiences. Seamlessly capture every moment, from team-building activities to conferences, leaving a lasting impression. With Memshots, turn ordinary events into extraordinary memories, fostering team spirit and leaving participants inspired.`}
      />
      <CoverImage imageLeft={imageLeft} imageRight={imageRight} />
      <ProductsForCorporates />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Testimonials
        title={"Elevate Your Corporate Events to Unforgettable Experiences!"}
        testimonialOne={`"Memshots Shared Memories Album truly enhanced our corporate event experience. Revisiting those memories sparked nostalgia and left us inspired to stay engaged with the brand" - Robert`}
        testimonialTwo={
          '"AI Face Detection by Memshots transformed our corporate event! Accessing the images seamlessly was a game-changer. Highly recommended for any corporate gathering" - Paul'
        }
      />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Features />
      <FooterShort />
      <div
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        className="fixed bottom-[35px] right-[10px] cursor-pointer"
      >
        <img
          src={backToTop}
          alt=""
          className="w-[32px] lg:w-[52px] h-[32px] lg:h-[52px] backToTopShadow rounded-full"
        />
      </div>
    </div>
  );
}

export default PitchForCorporates;
