import React from "react";
import styles from "../styles.module.css";
import autographCorporates from "../../../Assets/Images/Pitch/autographCorporates.png";
import sharedAlbumCorporates from "../../../Assets/Images/Pitch/sharedAlbumCorporates.png";
import faceDetectionCorporatess from "../../../Assets/Images/Pitch/faceDetectionCorporatess.png";
import sentOffAutograph from "../../../Assets/Images/Pitch/sentOffAutograph.png";
import annualConferenceFaceDetection from "../../../Assets/Images/Pitch/annualConferenceFaceDetection.png";
import appreciationAutograph from "../../../Assets/Images/Pitch/appreciationAutograph.png";
import awardCeremonySharedMemory from "../../../Assets/Images/Pitch/awardCeremonySharedMemory.png";
import corporateTourSharedMemory from "../../../Assets/Images/Pitch/corporateTourSharedMemory.png";
import { Link, useNavigate } from "react-router-dom";
import goToIcon from "../../../Assets/Images/Home/goToIcon.svg";

function ProductsForCorporates() {
  const weddingAutographHighlights = [
    "Cherished Memories",
    "Seamless Sharing",
    "Effortless Communication",
  ];
  const sentOffAutographHighlights = [
    "Inspiring Engagement",
    "Customized Photo Collections",
    "Personalized Corporate Experience",
  ];
  const annualConferenceHighlights = [
    "Effortless Smile Capture",
    "Comprehensive Facial Recognition",
    "Personalised Experience",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.productsDiv}>
        <ProductLists
          image={appreciationAutograph}
          heading={"Appreciation Autograph"}
          subHeading={
            "Appreciation Autograph is a unique corporate initiative designed to foster a culture of gratitude and recognition within organizations. With Appreciation Autograph, employees can express their appreciation and recognition for their colleagues' hard work, dedication, and contributions in a personalized and heartfelt manner."
          }
          highlights={weddingAutographHighlights}
          colour={"#FF6D3A"}
          sampleUrl={
            "https://app.memshots.com/176appreciationau/memories/allWishes?from=memshotscorporates"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={sentOffAutograph}
          heading={"Farewell Autograph"}
          subHeading={
            "Saying goodbye is tough, but Memshots Farewell Autograph makes it special. It captures signatures, messages, and memories, offering closure and celebrating each person's impact. It's a heartfelt way to make farewells memorable and honor contributions to the workplace or community."
          }
          highlights={sentOffAutographHighlights}
          colour={"#9747FF"}
          sampleUrl={
            "https://app.memshots.com/155farewellautogr/memories/allWishes?from=memshotscorporates"
          }
          purchaseUrl={"/buynow?from=autograph"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Autograph"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={awardCeremonySharedMemory}
          heading={"Award Ceremony"}
          subHeading={
            "Experience the magic of your Award Ceremony with Memshots Shared Memories Album. Craft personalized photo collections, highlighting memorable moments for attendees. Effortlessly share photos and messages, creating lasting keepsakes for all to cherish and reminisce about their special event memories."
          }
          highlights={sentOffAutographHighlights}
          colour={"#9747FF"}
          sampleUrl={
            "https://app.memshots.com/178awardceremony/sharedalbum?from=memshotscorporates"
          }
          purchaseUrl={"/buynow?from=sharedMemories"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Shared Memories"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={corporateTourSharedMemory}
          heading={"Corporate Tour"}
          subHeading={
            "Share trip memories easily with coworkers. Swap photos and signatures to build strong bonds and fond memories together. It brings us closer and helps us remember fun times, making our team stronger and more united."
          }
          highlights={sentOffAutographHighlights}
          colour={"#9747FF"}
          sampleUrl={
            "https://app.memshots.com/177corporatetour/sharedalbum?from=memshotscorporates"
          }
          purchaseUrl={"/buynow?from=sharedMemories"}
          buttonTextSample={"View Sample"}
          buttonTextUrl={"Buy Shared Memories"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={annualConferenceFaceDetection}
          heading={"Annual Conference "}
          subHeading={
            "Simplify your event experience with our AI-Based Photo Delivery Tool at the Annual Conference. With just a selfie, attendees effortlessly receive all event photos, streamlining access and ensuring memorable moments are easily accessible to all."
          }
          highlights={annualConferenceHighlights}
          colour={"#098C55"}
          sampleUrl={"https://photographers.memshots.com"}
          buttonTextSample={"Explore"}
        >
          <div className=" w-full flex justify-center px-[20px] lg:px-[80px] mt-[40px] lg:mt-[60px]">
            <div className="horizontalSeperator "></div>
          </div>
        </ProductLists>

        <ProductLists
          image={faceDetectionCorporatess}
          heading={"Employee Meet Up "}
          subHeading={
            "Elevate your Employee Meet Up with our seamless photo delivery. Our top-notch Face Recognition technology ensures each guest receives their personalized photo memories effortlessly, making it easy for everyone to cherish their special moments from the event."
          }
          highlights={annualConferenceHighlights}
          colour={"#098C55"}
          sampleUrl={"https://photographers.memshots.com"}
          buttonTextSample={"Explore"}
        />
      </div>
    </div>
  );
}

export default ProductsForCorporates;

function ProductLists({
  image,
  heading,
  subHeading,
  highlights,
  colour,
  sampleUrl,
  purchaseUrl,
  buttonTextSample,
  buttonTextUrl,
  children,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.productCards}>
        <img src={image} alt="" />
        <div className="flex flex-col justify-between gap-[40px]">
          <div>
            <p className="font_semibold_32_24 text-[#161616]">{heading}</p>
            <p className="mt-[16px] lg:mt-[24px]">{subHeading}</p>
            <ul className={styles.productsGridContainer}>
              {highlights.map((obj, i) => (
                <li
                  style={{
                    backgroundColor: `${colour}`,
                  }}
                  key={i}
                >
                  {obj}
                </li>
              ))}
            </ul>
          </div>
          <div className=" gap-[24px] items-end hidden xl:flex">
            <p className="dmSerif_italic_blue font_normal_24">
              {/* {buttonTextSample === "Explore"
                ? "Visit memshots photographers"
                : "View a sample"} */}
            </p>
            <Link to={sampleUrl}>
              {buttonTextSample === "Explore" ? (
                <>
                  {/* <button
                    className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                  >
                    <p>Explore</p>
                    <img src={goToIcon} alt="" />
                  </button> */}
                </>
              ) : (
                <button className={styles.tryDemoButton}>
                  {buttonTextSample}
                </button>
              )}
            </Link>
            {/* {buttonTextUrl && (
              <button
                onClick={() => navigate(`${purchaseUrl}`)}
                className={styles.tryDemoButton}
              >
                {buttonTextUrl}
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] xl:hidden mb-[40px]">
        <p className="dmSerif_italic_blue font_normal_24 ">
          {/* {buttonTextSample === "Explore"
            ? "Visit memshots photographers"
            : "View a sample"} */}
        </p>
        <div className="flex flex-col sm:flex-row gap-[12px]">
          <Link to={sampleUrl}>
            {buttonTextSample === "Explore" ? (
              <>
                {/* <button
                  className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                >
                  <p>Explore</p>
                  <img src={goToIcon} alt="" />
                </button> */}
              </>
            ) : (
              <button className={styles.tryDemoButton}>
                {buttonTextSample}
              </button>
            )}
          </Link>
          {/* {buttonTextUrl && (
            <button
              onClick={() => navigate(`${purchaseUrl}`)}
              className={styles.tryDemoButton}
            >
              {buttonTextUrl}
            </button>
          )} */}
        </div>
      </div>
      {children}
    </div>
  );
}
