import React, { useContext, useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router";
import AuthContext from "../../AuthContext";
import SignupPage from "../../Pages/Signup/SignupPage";
import axios from "axios";
import wishesBgImage from "../../Assets/Images/Home/wishesImage.png";
import { Link } from "react-router-dom";

function WishTool({
  setWishSelected,
  setSelectedWishpackage,
  packagePricing,
  wishBaseAmount,
}) {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  const handleSubmit = () => {
    navigate("buynow");
  };

  return (
    <>
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div className={styles.container} id="autograph">
        <div className={`bg-[#ede0ff] md:bg-transparent ${styles.wishToolDiv}`}>
          <div className="w-full">
            {/* <p className={`font_normal_40_32 dmSerifRegularItalic `}>
              Wish your loved ones for any special day!
            </p> */}
          </div>
          <div className=" gap-[40px] lg:mt-[10px] w-full flex flex-col-reverse lg:grid grid-cols-2 lg:gap-[60px]">
            <div>
              <p className="font_medium_16_14 tracking-[.2em] bg-[#be8cff] text-white px-[14px] py-[8px] w-fit mb-[10px]">
                DIGITAL SLAM BOOK
              </p>
              <p className={`font_normal_40_32 dmSerifRegularItalic `}>
                Wish your loved ones for any special day!
              </p>
              <p className="mt-[16px] font_normal_16">
                Digital Autograph / Slam Book is a modern, innovative way to
                capture and share personalized messages and signatures in the
                digital age.
              </p>
              <p className="mt-[12px] font_normal_16">
                Perfect for classmates' autographs, farewell messages, reviews,
                and any special event, digital autographs combine the
                authenticity of traditional signatures with the convenience of
                digital technology.
              </p>
              <div className={styles.wishToolPricing}>
                {/* <p className="font_bold_24">Ready to dive in?</p> */}
                <div className={styles.wishToolPricingSection}>
                  {/* <p className="dmSerif_italic_blue font_normal_24">
                Try a free demo today or buy it.
              </p> */}
                  <div className="flex gap-[12px]">
                    {/* <button
                  onClick={() => {
                    navigate("onlinewishbookdemo");
                  }}
                  className={styles.tryDemoButton}
                >
                  Try Demo
                </button> */}
                    <Link
                      to={
                        "https://app.memshots.com/176appreciationau/memories/allWishes?from=memshotscorporates"
                      }
                    >
                      <button className={styles.tryDemoButton}>
                        View Sample
                      </button>
                    </Link>
                    {/* <button
                  onClick={() => {
                    setWishSelected(true);
                    setSelectedWishpackage(
                      packagePricing?.wish && packagePricing?.wish[0]
                    );
                    handleSubmit();
                  }}
                  className={styles.buyNowButton}
                >
                  Buy Now
                </button> */}
                  </div>
                </div>
              </div>
              {/* <p className="font_semibold_18 mt-[40px]">{`Starting at ₹ ${wishBaseAmount}`}</p> */}
            </div>
            <div className="w-full">
              <img
                src={wishesBgImage}
                alt=""
                className="w-full object-cover max-h-[70vh] rounded-[10px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WishTool;
