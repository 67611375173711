import axios from "axios";
import React, { useEffect, useState } from "react";
import NavbarNew from "../Components/NavbarNew";
import VideoPlayPopup from "../Components/VideoPlayPopup/VideoPlayPopup";
import ReactPlayer from "react-player";
import "./Styles/tutorials.css";
import SEO from "../Components/SEO/SEO";

export default function Tutorials() {
  const [turtorials, setTurtorials] = useState([]);
  const [openVideo, setOpenVideo] = useState({ open: false, video: "" });

  useEffect(() => {
    getTurtorials();
  }, []);

  const getTurtorials = async () => {
    try {
      let config = {
        method: "get",
        url: "https://storage.googleapis.com/crm_tutorialvideos/tutorials.json",
      };
      const response = await axios(config);
      setTurtorials(response?.data);
    } catch (err) {
      console.log(err, "get turtorials error");
    }
  };

  return (
    <div className="containFull">
      <SEO
        title="Memshots Tutorials : AI Photo Delivery, Digital Albums, & More"
        description="Discover Memshots for photo management. Enjoy services like AI Face Detection, Online Invitations, Online Photo Albums, Photo Selection, and Digital Autographs."
        keywords="photo sharing, memories, gifts, custom albums, AI Face Detection, Online Invitations, Online Photo Albums, Photo Selection, Digital Autographs, tutorials memshots"
        url="https://memshots.com/tutorials"
        image="https://memshots.com/logo.png"
      />
      <NavbarNew />
      <div className="w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[50px] xl:mb-[100px]">
        <div className="mt-[40px]">
          <p className="turtorials pb-[30px]">Turtorials</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {turtorials?.map((item) => (
              <div className="toolsMainCard p-[16px]">
                <div
                  onClick={() => setOpenVideo({ open: true, video: item?.url })}
                  className="w-full h-[200px] relative cursor-pointer"
                >
                  <img
                    src={item?.thumbnail}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bg-opacity-[0.07] bg-[#111] w-full h-full justify-center items-center flex top-0 left-0 right-0 bottom-0">
                    <button className=" playBut ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="44"
                        viewBox="0 0 36 44"
                        fill="none"
                      >
                        <path
                          d="M35.75 22C35.7513 22.5518 35.6099 23.0945 35.3394 23.5754C35.0688 24.0562 34.6785 24.4589 34.2062 24.7443L4.94 42.6477C4.44658 42.9498 3.88145 43.1148 3.30297 43.1255C2.72449 43.1362 2.15364 42.9923 1.64938 42.7086C1.14991 42.4294 0.733842 42.0221 0.443958 41.5287C0.154073 41.0354 0.000834555 40.4737 0 39.9014V4.09863C0.000834555 3.5264 0.154073 2.96471 0.443958 2.47134C0.733842 1.97796 1.14991 1.5707 1.64938 1.29144C2.15364 1.00778 2.72449 0.863868 3.30297 0.874581C3.88145 0.885293 4.44658 1.05024 4.94 1.35238L34.2062 19.2558C34.6785 19.5412 35.0688 19.9438 35.3394 20.4247C35.6099 20.9056 35.7513 21.4483 35.75 22Z"
                          fill="#787878"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* <img className="rounded-[8px]" src={img} alt="" /> */}
                <div className="mt-[16px]">
                  <p className="welcome">{item?.title}</p>
                  <p className="welcometext">{item?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {openVideo?.open && (
          <VideoPlayPopup onClose={setOpenVideo} openVideo={openVideo} />
        )}
      </div>
    </div>
  );
}
