import React from "react";
import Navbar from "../../Components/Navbar";
import Events from "../../Components/Home/Events";
// import ClientsGrid from "../../Components/Clients/ClientsGrid";
import Clients from "../../Components/Home/Clients";
import NavbarNew from "../../Components/NavbarNew";
import SEO from "../../Components/SEO/SEO";

export default function ClientsPage() {
  return (
    <div>
      <SEO
        title="Memshots Clients : AI Photo Delivery, Digital Albums, & More"
        description="Discover Memshots for photo management. Enjoy services like AI Face Detection, Online Invitations, Online Photo Albums, Photo Selection, and Digital Autographs."
        keywords="photo sharing, memories, gifts, custom albums, AI Face Detection, Online Invitations, Online Photo Albums, Photo Selection, Digital Autographs, clients memshots"
        url="https://memshots.com/clients"
        image="https://memshots.com/logo.png"
      />
      <NavbarNew />
      <Clients wrap="wrap" type="grid" />
      <div className=" w-full flex justify-center px-[20px] lg:px-[80px]">
        <div className="horizontalSeperator max-w-[1440px]"></div>
      </div>
      <Events />
    </div>
  );
}
